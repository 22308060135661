//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: March 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
//
//=====================================================//

import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import { animateScroll as scroll } from 'react-scroll/modules'
import {
    Nav, 
    NavbarContainer, 
    NavLogoWrapper, 
    NavLogoImg1, 
    NavLogoImg2, 
    // NavLogo, 
    MobileIcon, 
    NavMenu, 
    NavItem, 
    NavLinks, 
    NavLinksEx, 
    NavBtn, 
    NavBtnLink
} 
from './navBarElements'
import {FaBars} from 'react-icons/fa'
import { IconContext } from 'react-icons/lib'
import mainLogoL from '../../images/tivos-light-logo.png'
import mainLogoD from '../../images/tivos-dark-logo.png'

/* Env variables*/
const tivosLogin = process.env.REACT_APP_LOGIN

const goToPreRegistro = () => {
    window.location = "./Solicitud"
}

const goToLogin = () => {
    window.location = "./Login"
}

const Navbar = ({ toggle }) => {
    const [scrollNav, setScrollNav] = useState(false);

    const changeNav = () =>{
        if(window.scrollY >= 80){
            setScrollNav(true)
        }else{
            setScrollNav(false)
        }
    }

    useEffect(()=> {
        window.addEventListener('scroll', changeNav)
    },[])

    const toggleHome = () => {
        scroll.scrollToTop();
    }
    
    const navigate = useNavigate();

    const returnMain = () => {
        navigate('/');
        //document.getElementById('services').scrollIntoView({behavior : 'smooth'});
    }

    return (
        <>
        <IconContext.Provider value={{ color: '#1EB445' }}>
            <Nav scrollNav={scrollNav}>
                <NavbarContainer>
                    {/* <NavLogo to="/" onClick={toggleHome}>Tivos^</NavLogo> */}
                    <NavLogoWrapper to="/" onClick={toggleHome}>
                        <NavLogoImg1 scrollNav={scrollNav} src={mainLogoL}/>
                        <NavLogoImg2 scrollNav={scrollNav} src={mainLogoD}/>
                    </NavLogoWrapper>
                    <MobileIcon onClick={toggle}>
                        <FaBars />
                    </MobileIcon>
                    <NavMenu>
                        <NavItem>
                            <NavLinks scrollNav={scrollNav} to="services" smooth={true} duration={500} spy={true} exact='true' offset={-280} onClick={returnMain}>Nosotros</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks scrollNav={scrollNav} to="industry" smooth={true} duration={500} spy={true} exact='true' offset={-80} onClick={returnMain}>Soluciones</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks scrollNav={scrollNav} to="simulador" smooth={true} duration={500} spy={true} exact='true' offset={-80} onClick={returnMain}>Simulador</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinksEx scrollNav={scrollNav} href="./VendorProgram" target="_blank">Vendor Program</NavLinksEx>
                        </NavItem>
                         <NavItem>
                             <NavLinksEx scrollNav={scrollNav} href="https://tivos.blog/" target='_blank' >Blog</NavLinksEx>
                         </NavItem>                    
                    </NavMenu>
                    <NavBtn>
                        <NavBtnLink onClick={goToLogin}>Inicia Sesi&oacute;n</NavBtnLink>
                        {/* <NavBtnLink onClick={goToPreRegistro}>Inicia tu Solicitud</NavBtnLink> */}
                    </NavBtn>
                </NavbarContainer>
            </Nav>
        </IconContext.Provider>
        </>
    )
}

export default Navbar
