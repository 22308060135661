//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: March 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
//
//=====================================================//

import React, {useState, useEffect} from 'react'
// import { animateScroll as scroll } from 'react-scroll/modules'
import Video from '../../images/grupo_1.png'
import { HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward, ArrowRight } from './HeroElements'
import {Button} from '../ButtonElement'

const goToPreRegistro = () => {
    window.location = "./Solicitud"
}


function HeroSection() {
    const [hover, setHover] = useState(false)
    const [scrollNav, setScrollNav] = useState(false);

    const changeNav = () =>{
        if(window.scrollY >= 80){
            setScrollNav(true)
        }else{
            setScrollNav(false)
        }
    }

    useEffect(()=> {
        window.addEventListener('scroll', changeNav)
    },[])

    const onHover = () => {
        setHover(!hover)
    }

    return (
        <HeroContainer id="home">
            <HeroBg>
                <VideoBg src={Video} />
                {/* <VideoBg autoPlay loop muted src={Video} type='video/mp4' /> */}
            </HeroBg>
            <HeroContent>
                <HeroP>Crece tu negocio exponencialmente</HeroP>
                <HeroH1>Personalizamos el arrendamiento puro a tasas altamente competitivas</HeroH1>
                <HeroBtnWrapper>
                    {/* <Button  scrollNav={scrollNav} to="/Formulario" smooth={true} duration={500} spy={true} exact='true' offset={-80} onMouseEnter={onHover} onMouseLeave={onHover} primary="true" dark="true">
                        Inicia tu Solicitud{hover ? <ArrowForward /> : <ArrowRight />}
                    </Button> */}
                    <Button onClick={goToPreRegistro} offset={-80} onMouseEnter={onHover} onMouseLeave={onHover} primary="true" dark="true">
                        Inicia tu Solicitud{hover ? <ArrowForward /> : <ArrowRight />}
                    </Button>
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
    )
}

export default HeroSection
