//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: March 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
//
//=====================================================//

import styled from 'styled-components'

export const ServicesContainer = styled.div`
    height: ${({vendor})=>( vendor ? '450px' : '150px' )};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    position: relative;
    z-index: 1;

    @media screen and (max-width: 1000px){
        height: 600px;
        margin-top: -80px;
    };

    @media screen and (max-width: 768px){
        height: 600px;
        margin-top: -80px;
    };

    @media screen and (max-width: 480px){
        height: 1100px;
    };
`;

export const ServicesWrapper = styled.div`
    max-width:${({vendor})=>( vendor ? '1600px' : '1200px' )};
    margin: ${({vendor})=>( vendor ? '5% auto -2% auto' : '3% auto 5% auto' )};
    display: grid;
    grid-template-columns: ${({vendor}) => ( vendor ? '1fr 1fr 1fr 1fr 1fr' : '1fr 1fr 1fr 1fr')};
    align-items: center;
    grid-gap: 36px;
    padding: 0 50px;

    @media screen and (max-width: 1200px){
        max-width: 90%;
        margin-left: 3%;
    };

    @media screen and (max-width: 1100px){
        max-width: 90%;
        margin-left: -3%;
    };

    @media screen and (max-width: 1000px){
        grid-template-columns: 1fr 1fr;
        max-width: 90%;
        margin-left: 4%;
        height: 600px;
    };

    @media screen and (max-width: 768px){
        grid-template-columns: 1fr 1fr;
        padding: 0 20px;
    };

    @media screen and (max-width: 480px){
        grid-template-columns: 1fr;
        padding: 0 20px;
        height: 1100px;
    };
`;

export const ServicesCard = styled.div`
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    max-height: 340px;
    padding: 30px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;
    margin-top: -150px;

    &:hover{
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        border-bottom: 2px solid #1EB445;
    }

    @media screen and (max-width: 1000px){
        margin-top: 10px;
    }

`;

export const ServicesIcon = styled.img`
    height: 160px;
    width: 160px;
    margin-bottom: 10px;
`;

export const ServicesIcon2 = styled.img`
    height: 30px;
    width: 30px;
    margin-bottom: 10px;
`;

export const ServicesH1 = styled.h1`
    font-size: 2.5rem;
    font-weight: 300;
    color: #1EB445;
    margin: 10% auto 5% auto;
    z-index: 1001;

    @media sceen and (max-width: 480){
        font-size: 2rem; 
    }
`;

export const ServicesH2 = styled.h2`
    font-size: 1rem;
    margin-bottom: 10px;
    text-align: center;
    color: ${({vendor}) => (vendor ? '#1EB445' : 'black')};
`;

export const ServicesP = styled.p`
    text-size: 1rem;
    text-align: center;
    color: transparent; 
    max-height: 0;
    transition: all .5s ease-out;
    
    ${ServicesCard}:hover &{
        color: gray;
        max-height: 500px;
        transition: all 1s ease !important;
    }
`;
