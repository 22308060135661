//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: March 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
//
//=====================================================//

import React , {useState,useEffect} from 'react'
//import { md5 } from "blueimp-md5";
import {
    LoginContainer,
    LoginWrapper,
    LoginInfo,
    LoginButtons,
    Logo,
    Label,
    Input,
    LoginImage,
    LoginRow,
    LoginColumn1,
    Terms,
    GoToMain,
    LoginColumn2,
    GlobalStyle,
    ForgotPassword,
    FormPopup,
    PopupTitle,
    PopupMessage,
    FormButton,
    ButtonFinalSection,
    FormCheckBox,
    CheckPassword
} from './LoginElements'
import img2 from "../../images/Company-amico_WOplaque.png";
import logo from "../../images/tivos-dark-logo.png";
import { Button } from '../ButtonElement'
import { verifyLogin } from '../../Connections/GeneralConn'
const md5 = require("blueimp-md5");
//=====================================================
//    Variables Globales
//=====================================================

    const dashboard = process.env.REACT_APP_DASHBOARD
    var errorMessage;

//=====================================================s

const Login = () => {

    //=======================================================
    //    Handle onEnter key
    //=======================================================

    const keyPressHandler = event => {
        if (event.key === 'Enter') {
            login();
          }
    };
    
    document.addEventListener('keydown', keyPressHandler);
    //=======================================================
    //    UseStare Variables
    //=======================================================

    const [showP, setShowP] = useState(false);
    const [blur, setBlur] = useState('');

    //=======================================================
    //    Links Manager
    //=======================================================

    function goto(v){
        switch(v){
            case 0:
                window.location = '/CreatePassword';
                break;
            case 1:
                window.location = '/TerminosCondiciones';
                break;
            case 2:
                window.location = '/'
                break;
        }
    };

    //=========================================================
    //    Popup manager
    //=========================================================

    function closePopup(){
        setShowP(!showP)
        setBlur(!blur)

    };

    //=========================================================

    //=========================================================
    //    show Password
    //=========================================================

    function showPwd() {
        let show = document.getElementById('showPassword').checked;
        let pwd = document.getElementById('password');
        let confirmPwd = document.getElementById('confirmPassword');

        if(show){
            pwd.type = "text";
            confirmPwd.type = "text";
        }else{
            pwd.type = "password";
            confirmPwd.type = "password";
        }
    };


    //=========================================================
    //    Login Process
    //=========================================================

    function login(){
        let email = document.getElementById('email').value;
        let pwd = (document.getElementById('password').value);
        let token,userType;        
        let data = JSON.stringify({
            userName: email,
            password: md5(pwd)
        });

        if(email == '' && pwd == ''){
            errorMessage = 'Por favor, ingresa tus datos para continuar';
            setBlur(!blur)
            setShowP(!showP);
        }else if(email ==''){
            errorMessage = 'Por favor, ingresa tu correo para continuar';
            setBlur(!blur)
            setShowP(!showP);
        }else if(pwd ==''){
            errorMessage = 'Por favor, ingresa tu contraseña para continuar';
            setBlur(!blur)
            setShowP(!showP);
        }else{
            verifyLogin(data,(response)=>{

                if(response.error != 0){
                    errorMessage = response.message;
                    setBlur(!blur)
                    setShowP(!showP);
                }else{
                    token = response.token
                    userType = response.userType
                    if(userType == 2)window.location.href = (dashboard+'Clients?id='+token);
                    else window.location.href = (dashboard+'Dashboard?id='+token);                    
                }

            })
        }

    }
    
    //=========================================================
    //=========================================================
    //    Intercom chat settings
    //=========================================================
    
    window.intercomSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: "qkws515p"
        // name: "<%= David %>", // Nombre completo
        // email: "<%= dave@tivos.mx %>", // Dirección de correo electrónico
        // created_at: "<%= current_user.created_at.to_i %>" // Fecha de registro como marca de tiempo de Unix
    };
    // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/qkws515p'
    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/qkws515p';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();    
    
    //=========================================================
    return (
        <>
        <GlobalStyle/>

        <LoginContainer>

            <FormPopup show={showP}>
                <PopupTitle>¡Hemos encontrado un error!</PopupTitle>
                <PopupMessage>{errorMessage}</PopupMessage>
                <FormButton>
                    <ButtonFinalSection>
                        <Button small primary dark onClick={closePopup}>Cerrar</Button>
                    </ButtonFinalSection>
                </FormButton>
            </FormPopup>

            <LoginWrapper blur={showP}>
                <LoginRow>
                    <LoginColumn1>
                        <LoginInfo>
                            <Logo src={logo}></Logo>
                            <Label>Inicia Sesi&oacute;n</Label>
                            <Input placeholder='Correo electronico*' type="email" id="email"></Input>
                            <Input placeholder='Contraseña*' type="password" id="password"></Input>
                            <CheckPassword><FormCheckBox type="checkbox" id='showPassword' onClick={showPwd}></FormCheckBox>Mostrar Contraseña</CheckPassword>
                            <ForgotPassword onClick={() => goto(0)}>¿Olvidaste tu Contraseña?</ForgotPassword>
                            <Button margin primary dark medium onClick={login}>Iniciar Sesi&oacute;n</Button>
                        </LoginInfo>
                        <LoginButtons>
                            <Terms onClick={() => goto(1)}>T&eacute;rminos y Condiciones</Terms>
                            <GoToMain onClick={() => goto(2)}>&larr; Regresa a tivos.mx</GoToMain>
                        </LoginButtons>
                    </LoginColumn1>
                    <LoginColumn2>
                        <LoginImage src={img2}></LoginImage>
                    </LoginColumn2>
                </LoginRow>
            </LoginWrapper>
        </LoginContainer>
        </>
    )
}

export default Login