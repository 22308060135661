//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: March 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
//
//=====================================================//

import React from 'react'
import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Home from './pages';
import Aviso from './components/Documents/AvisoPrivacidad';
import Terminos from './components/Documents/TerminosCondiciones';
import PreRegistroForm from './components/Formularios/Solicitud';
import NewRegistroForm from './components/Formularios/SolicitudBroker';
import Login from './components/Login/Login';
import CreatePassword from './components/Login/CreatePassword';
import CuentasOk from './components/CuentasOk/CuentasOk';
import VendorProgram from './components/VendorProgram/VendorProgram';


// Limpiamos toda la info que pueda existir.
//Set your APP_ID
var APP_ID = "qkws515p";

(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();

function App() {
  return (
    <Router className="App">
      <Routes>
        <Route path="/" element={<Home/>} exact />
        <Route path="/AvisoPrivacidad" element={<Aviso/>} exact/>
        <Route path="/TerminosCondiciones" element={<Terminos/>} exact/>
        <Route path="/Solicitud" element={<PreRegistroForm/>} exact/>
        <Route path="/SolicitudBroker" element={<NewRegistroForm/>} exact/>
        <Route path="/login" element={<Login/>} exact/>
        <Route path="/CreatePassword" element={<CreatePassword/>} exact/>
        <Route path="/VendorProgram" element={<VendorProgram/>} exact/>
        <Route path="/CuentasOk" element={<CuentasOk/>} exact/>
      </Routes>
    </Router>
  );
}

export default App;
