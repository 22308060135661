//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: March 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
//
//=====================================================//

import styled from "styled-components";
import { Link } from 'react-router-dom';
import img from "../../images/bkg_light_hd.png";

// export const Body = styled.body `
//     background-color: #101522;    
// `;

export const FormularioContainer = styled.div `
    background-image: ${({bkgrnd}) => ( bkgrnd ? `url(${img})`:'none')};
    //background-color: #e6e6e6;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-size: cover;
    margin-top: ${({broker}) => ( broker ? '': '80px')};
    `;
    
export const FormularioWrapper = styled.div `
    display:${({ show }) => ( show ? 'block':'none')};
    background: #fff;
    margin: 5%;
    padding: 2.5% 3.5%;
    border-radius: 10px;

    @media screen and (max-width: 760px){
        width: 90%;
    }
`;

export const FormTitle = styled.h1 `
    margin-bottom: 5%;
    color: #1EB445;
    display: ${({ appear }) =>( appear ? 'none' : 'block')};
`;

export const QuestionWrapper = styled.div ``;

export const FormItemL = styled.div `
    margin: 15px 0;
    width: 600px;
    font-size: 1.2em;
    display: ${({ appear }) =>( appear ? 'none' : 'block')};

    @media screen and (max-width: 760px){
        width: 100%;
    }

`;

export const FormInput = styled.input `
    display: ${({ show }) =>( show ? 'none' : 'block')};
    margin-bottom: 5px;
    width: 600px;
    height: 35px;
    border-radius: 25px;
    padding: 3%;
    font-size: 1em;
    background: #f3f3f3;
    border: none;

    &:focus{outline: solid 2px #1EB445 ;}
    
    @media screen and (max-width: 760px){
        width: 100%;
    }


`;

export const FormSelect = styled.select `
    display: ${({ show }) =>( show ? 'none' : 'block')};
    margin-bottom: 5px;
    width: 600px;
    height: 35px;
    border-radius: 25px;
    font-size: 1em;
    padding: 1% 3%;
    background: #f3f3f3;
    border: none;

    &:focus{outline: solid 2px #1EB445 ;}
    
    @media screen and (max-width: 760px){
        width: 100%;
    }

`;

export const FormOption = styled.option `
`;

export const FormButton = styled.div `
    margin-top: 30px;
`;

export const FormCheckBox = styled.input `
    margin-right: 10px;
    color: #01bf71;
`;

export const FormItemLSub = styled.div `
    display:${({ show }) => ( show ? 'block':'none')};
    font-size: 1em;
    margin-top: 5%;
    text-align: center;
`;

export const ButtonSection = styled.div `
    width: 40%;
    float: left;
    margin-left: 7%;
`;

export const FormLinkPage = styled(Link)`
    color: #1EB445;
    text-decoration: none;
    margin-bottom: 0.5rem;
    font-size: 1em;
    cursor: pointer;

    &:hover{
        text-decoration: underline;
        transition: 0.3s ease-out;
    }
`;

export const FormSubText = styled.div`
    font-size: 1em;
    margin-top: 2%;
    color: #1EB445;
    line-height: 1.5;
    background-color: #f3f3f3;
`;

export const ProgressBar = styled.div`
    background-image: linear-gradient(to right, #e6e6e6, #1EB445);
    height: 7.5px;
    width: 100%;
    position: absolute;
    transform: translateX(${per => per.percent.main});
    border-radius: 10px;
`;

export const FormularioSplash = styled.div`
    display:${({ show }) => ( show ? 'block':'none')};
    padding: 10%;
`;

export const FormTitleSplash = styled.h1 `
    margin-bottom: 5%;
    text-align: center;
    color: #1EB445;
`;

export const FormSubTitleSplash = styled.p `
    margin-bottom: 5%;
    text-align: center;
    color: #000;
    font-weight: 400;
    line-height: 2rem;
`;

export const ButtonFinalSection = styled.div `
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 4%;
`;

export const BlackSubText = styled.strong `
`;

export const FormPopup = styled.div`
    display:${({ show }) => ( show ? 'block':'none')};
    height: auto;
    width: 450px;
    background-color: #fff;
    position: absolute;
    top: 200px;
    border: 2px solid #1EB445;
    border-radius: 5px;
`;

export const PopupTitle = styled.h2`
    margin-top: 5%;
    text-align: center;
    color: #1EB445;
`;

export const PopupMessage = styled.p`
    margin-top: 5%;
    padding: 2%;
    text-align: center;
    font-size: 1.2em;
`;

export const LinkedInWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const LinkedInIcon = styled.img`
`;