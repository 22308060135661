//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: March 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
//
//=====================================================//


//=====================================================
//    Funcion para llamar APIs externas
//=====================================================

//  Variables del entorno
const apiTivos = process.env.REACT_APP_API
const monday = process.env.REACT_APP_MONDAY

export function appExtConnLead(input,api,res){
    switch(api){
        case 'Log':
        
            fetch('https://hooks.zapier.com/hooks/catch/11851902/3mg4m44/',{
                method : 'POST',
                body : input
            })
            .then(response => response.json())
            .then(result => res(result))
            .catch(error => res(error))
            break;
        
        case 'monday':
            fetch(monday,{
                method : 'POST',
                body : input
            })
            .then(response => response.json())
            .then(result => res(result))
            .catch(error => res(error))        
            break;
        default:
            console.log('No hubo alguna accion.')
            break;

    }

};

//-----------------------------------------------------

//=====================================================
//  Function para conectar con APIs 
//=====================================================

export function tivosApi(data,response){
    var request = {
        method : 'POST',
        headers : {
            'Content-Type' : 'application/json'
            },
        body : data,
    }

    fetch(apiTivos+'SaveInformation',request)
    .then(response => response.json())
    .then(result => response(result))
    .catch(function(error){console.log('error',error)})

};

//=====================================================
//    Login process begins
//=====================================================

export function verifyLogin(data,response){
    var request = {
        method : 'POST',
        headers : {
            'Content-Type' : 'application/json'
            },
        body : data,
    }

    fetch(apiTivos+'Verification',request)
    .then(response => response.json())
    .then(result => response(result))
    .catch(error => response(error))
};

//=====================================================
//    Login process begins
//=====================================================

export function savePassword(data,response){
    var request = {
        method : 'POST',
        headers : {
            'Content-Type' : 'application/json'
            },
        body : data,
    }

    fetch(apiTivos+'SavePassword',request)
    .then(response => response.json())
    .then(result => response(result))
    .catch(function(error){console.log('error',error)})
};

//=====================================================
