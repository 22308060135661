//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: March 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
//
//=====================================================//

import React , {useState} from 'react'
//import { md5 } from "blueimp-md5";
import {
    LoginContainer,
    LoginWrapper,
    LoginInfo,
    Logo,
    Label,
    Input,
    LoginImage,
    LoginRow,
    LoginColumn1,
    LoginColumn2,
    GlobalStyle,
    FormPopup,
    PopupTitle,
    PopupMessage,
    FormButton,
    ButtonFinalSection,
    FormItemLSub,
    FormCheckBox,
    FormLinkPage,
    CheckPassword,
    GoToLogin,
    WhatsAppContainer,
    WhatsAppLogo
} from './LoginElements'
import img3 from "../../images/Reset password-bro.svg";
import logo from "../../images/tivos-dark-logo.png";
import whatsapplogo from "../../images/whatsapp.png";
import { Button } from '../ButtonElement'
import { savePassword } from '../../Connections/GeneralConn'
const md5 = require("blueimp-md5");

//=====================================================
//    Variables Globales
//=====================================================

    var errorMessage;
    var errorMessage_1 = '';
    var errorMessage_2 = '';

//=====================================================

const CreatePassword = () => {
    //=======================================================
    //    Links Manager
    //=======================================================

    function goto(v){
        switch(v){
            case 0:
                window.location = '/Login';
                break;
            case 1:
                window.location = '/TerminosCondiciones';
                break;
            case 2:
                window.location = '/'
                break;
        }
    };

    //=======================================================
    //    UseStare Variables
    //=======================================================

    const [showP, setShowP] = useState(false);
    const [showP1, setShowP1] = useState(false);
    const [showP2, setShowP2] = useState(false);
    const [blur, setBlur] = useState('');

    //=========================================================
    //    Popup manager
    //=========================================================

    const closePopup = () =>{
        setShowP(!showP)
        setBlur(!blur)        
    };

    const closeSuggestion = () =>{
        setShowP2(!showP2)
        setBlur(!blur)        
    };

    //=========================================================
    
    // =========================================================
    //    show Password
    // =========================================================

    window.onload = function() {
        setShowP2(!showP2);
        setBlur(!blur)        
    };

    // =========================================================
    
    //=========================================================
    //    Go to Login
    //=========================================================

    function login(){

        window.location = '/login'

    };

    //=========================================================

    //=========================================================
    //    show Password
    //=========================================================

    function showPwd() {
        let show = document.getElementById('showPassword').checked;
        let pwd = document.getElementById('password');
        let confirmPwd = document.getElementById('confirmPassword');

        if(show){
            pwd.type = "text";
            confirmPwd.type = "text";
        }else{
            pwd.type = "password";
            confirmPwd.type = "password";
        }
    };

    //=========================================================

    //=========================================================
    //    Login Process
    //=========================================================

    function confirmPassword(){
        let email = document.getElementById('email').value;
        let pwd = (document.getElementById('password').value);
        let pwdV = (document.getElementById('confirmPassword').value);
        let priv = document.getElementById('privacidadCheck').checked;

        if(priv){
            if(email == '' || pwd == '' || pwdV == ''){
                errorMessage = 'Por favor llena todos los campos'
                setShowP(!showP)
                setBlur(!blur)    
            }else{
                if(pwd == pwdV){
                    let data = JSON.stringify({
                        userName: email,
                        password: md5(pwd)
                    });
                    
                    savePassword(data,(response)=>{

                        if(response.error == 1){
                            errorMessage = 'Correo electrónico inválido. Te pedimos que revises nuevamente tu correo. Recuerda que el correo debe coincidir con el que ingresaste tu solicitud en tivos^.';
                            setBlur(!blur)
                            setShowP(!showP);
                        }else{
                            errorMessage = 'La contraseña ha sido creada exitosamente.';
                            setBlur(!blur)
                            setShowP1(!showP1);                    
                        }
            
                    })
                }else{
                    errorMessage = 'Las contraseñas no coinciden'
                    setShowP(!showP)
                    setBlur(!blur)
                }
            }
        }else{
            errorMessage = 'Por favor, acepta los Términos y Condiciones.'
            setShowP(!showP)
            setBlur(!blur)
        }

    }
    
    //=========================================================
    //=========================================================
    //    Intercom chat settings
    //=========================================================
    
    window.intercomSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: "qkws515p"
        // name: "<%= David %>", // Nombre completo
        // email: "<%= dave@tivos.mx %>", // Dirección de correo electrónico
        // created_at: "<%= current_user.created_at.to_i %>" // Fecha de registro como marca de tiempo de Unix
    };
    // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/qkws515p'
    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/qkws515p';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();    
    
    //=========================================================
    return (
        <>
        <GlobalStyle/>

        <LoginContainer>

            <FormPopup show={showP}>
                <PopupTitle>¡Hemos encontrado un error!</PopupTitle>
                <PopupMessage>{errorMessage}</PopupMessage>
                <FormButton>
                    <ButtonFinalSection>
                        <Button small primary dark onClick={closePopup}>Cerrar</Button>
                    </ButtonFinalSection>
                </FormButton>
            </FormPopup>

            <FormPopup show={showP1}>
                <PopupTitle>¡FELICIDADES!</PopupTitle>
                <PopupMessage>{errorMessage}</PopupMessage>
                <FormButton>
                    <ButtonFinalSection>
                        <Button small primary dark onClick={login}>Ir al Login</Button>
                    </ButtonFinalSection>
                </FormButton>
            </FormPopup>

            <FormPopup show={showP2}>
                <PopupTitle>Tips para crear tu contraseña:</PopupTitle>
                <PopupMessage>
                    <ul style={{listStyle:'none'}}>
                        <li>1. Al menos 8 caracteres.</li>
                        <li>2. Combina números y letras.</li>
                        <li>3. Usa al menos un caracter especial.</li>
                        <li>4. Usa al menos una letra mayúscula.</li>
                    </ul>
                </PopupMessage>
                <FormButton>
                    <ButtonFinalSection>
                        <Button small primary dark onClick={closeSuggestion}>Cerrar</Button>
                    </ButtonFinalSection>
                </FormButton>
            </FormPopup>

            <LoginWrapper blur={blur}>
                <LoginRow>
                    <LoginColumn1>
                        <LoginInfo>
                            <Logo src={logo}></Logo>
                            <Label>Crea tu contraseña</Label>
                            <Input placeholder='Correo electronico*' type="email" id="email"></Input>
                            <Input placeholder='Contraseña*' type="password" id="password"></Input>
                            <Input placeholder='Confirma tu contraseña*' type="password" id="confirmPassword"></Input>
                            <CheckPassword><FormCheckBox type="checkbox" id='showPassword' onClick={showPwd}></FormCheckBox>Mostrar Contraseña</CheckPassword>
                            <Button margin primary dark medium onClick={confirmPassword}>Guardar Contraseña</Button>
                        </LoginInfo>
                        <FormItemLSub><FormCheckBox type="checkbox" id='privacidadCheck'></FormCheckBox>Acepta nuestros <br></br><FormLinkPage to="/TerminosCondiciones">T&eacute;rminos y Condiciones.</FormLinkPage></FormItemLSub>
                        <GoToLogin onClick={() => goto(0)}>&larr; Regresa al Login</GoToLogin>
                    </LoginColumn1>
                    <LoginColumn2>
                        <LoginImage src={img3}></LoginImage>
                    </LoginColumn2>
                </LoginRow>
            </LoginWrapper>
            {/* <WhatsAppContainer>
                <WhatsAppLogo src={whatsapplogo}></WhatsAppLogo>
            </WhatsAppContainer> */}
        </LoginContainer>
        </>
    )
}

export default CreatePassword